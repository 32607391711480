import { lazy, Suspense } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import ScrollToTop from "./components/scrollToTop";

const Loader = (Components: any) => (props: any) => {
  return (
    <Suspense fallback={<CircularProgress variant="indeterminate" />}>
      <ScrollToTop />
      <Components {...props} />
    </Suspense>
  );
};

const Home = Loader(lazy(async () => await import("./pages/home")));
const NotFound = Loader(lazy(async () => await import("./pages/not-found")));

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
