import { ThemeProvider } from "@mui/system";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import styled, { defaultTritonTheme } from "./theme/theme-triton";
import "./index.css";

const ThemeComponent = styled("div")(({ theme }) => ({
  ".bgBlue": {
    backgroundColor: theme.palette.primary.main,
  },
  ".dive-transparance": {
    padding: "72px 0",
    boxSizing: "border-box",
  },
  ".img-fluid": {
    maxWidth: "100%",
  },
  ".overlay-dive": {
    position: "absolute",
    boxShadow: "0px 4px 40px rgba(18, 41, 69, 0.24)",
    top: "-200px",
  },
  ".position-relative": {
    position: "relative",
  },
  ".text-center": {
    textAlign: "center",
  },
  ".box-analysis": {
    position: "relative",
    width: "397px",
  },
  ".custom-container": {
    maxWidth: "1338px",
  },
}));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const loader = document.querySelector(".loader");

// if you want to show the loader when React loads data again
const showLoader = () => loader?.classList.remove("loader--hide");

const hideLoader = () => loader?.classList.add("loader--hide");

const cache = createCache({
  key: "css",
  prepend: true,
});

root.render(
  <React.StrictMode>
    <CacheProvider value={cache}>
      <ThemeProvider theme={defaultTritonTheme}>
        <BrowserRouter>
          <ThemeComponent>
            <App hideLoader={hideLoader} showLoader={showLoader} />
          </ThemeComponent>
        </BrowserRouter>
      </ThemeProvider>
    </CacheProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
