import { EffectCallback, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import routes from "./routers";
import "./App.css";

interface AppProps {
  hideLoader: EffectCallback;
  showLoader: EffectCallback;
}

function App(props: AppProps) {
  useEffect(props.hideLoader, []);

  return useRoutes(routes);
}

export default App;
